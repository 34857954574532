import React, {useEffect} from 'react';
import './Offer'

const Uric = () => {
    useEffect(()=>{
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    })
    return (
        <div className={'container'}>
            <div className={'offer single__news__section'}>
                <h1>Юридическая информация</h1>
                <div className={'offer__content'}>
                    <div className={'uric__section'}>
                        <h2>Юридический адрес</h2>
                        <p>Юридический адрес: 632382, РФ, Новосибирская область, г. Куйбышев, ул. Закраевского, д.105</p>
                    </div>
                    <div className={'uric__section'}>
                        <h2>Адрес для корреспонденции</h2>
                        <p>Почтовый адрес: 632387, РФ, Новосибирская область, г. Куйбышев, ул. К.Либкнехта, 1</p>
                    </div>
                    <div className={'uric__section'}>
                        <h2>Лицензии</h2>
                        <a href="https://spektr-tv.su/docs/lic_2.rtf"
                           className="text-center"><p>
                            [СКАЧАТЬ] Лицензия на оказания услуг связи для целей кабельного вещания
                        </p></a>
                        <a href="https://spektr-tv.su/docs/telematic.pdf"
                           className="text-center"><p>[СКАЧАТЬ] Лицензия на телематические услуги связи
                        </p></a>
                    </div>
                    <a href="https://spektr-tv.su/docs/doc.docx"
                       className="text-center">[СКАЧАТЬ]</a>
                    <div className="offer__rec">
                        <h3>Реквизиты:</h3>
                        Адреса и реквизиты ООО СКТВ «Спектр»<br/>
                        Почтовый адрес: 632387, Новосибирская область, г. Куйбышев, ул. К.Либкнехта, 1<br/>
                        ИНН 5452112252/КПП 545201001<br/>
                        Банковские реквизиты:<br/>
                        Р./Сч.: 40702810325040000320<br/>
                        В банке НОВОСИБИРСКИЙ РФ АО "РОССЕЛЬХОЗБАНК"<br/>
                        К./сч.: 30101810700000000784<br/>
                        БИК 045004784 <br/>
                        Тел. (38362) 51-451;8-913-391-08-08<br/>
                        E-mail: <a href="mailto:spektr-sktv@mail.ru">spektr-sktv@mail.ru</a><br/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Uric;