import React from 'react';
import {Breadcrumbs, Stack, Typography} from "@mui/material";
import PropTypes from "prop-types";
import TariffCard from "../../Component/Tariff/TariffCard";
import useMediaQuery from "@mui/material/useMediaQuery";

const PageTitle = ({ breadcrumbs, title, subtitle }) => {
    const isMobile = useMediaQuery('(max-width:600px)');
    return (
        <Stack py={4} pb={7} maxWidth={isMobile ? '100%' : '50%' }>
            <Breadcrumbs separator={<span style={{color:"#66B3EC"}}>/</span>} aria-label="breadcrumb" sx={{paddingBottom: 4}}>
                {breadcrumbs}
            </Breadcrumbs>
            <Stack gap={2}>
                <Typography variant={"h1"}>{title}</Typography>
                {
                    subtitle &&
                    <Typography variant={"primaryMedium"} color={"#666"}>
                        {subtitle}
                    </Typography>
                }
            </Stack>
        </Stack>
    );
};

PageTitle.propTypes = {
    breadcrumbs: PropTypes.node,
    title: PropTypes.string,
    subtitle: PropTypes.string,
};

export default PageTitle;