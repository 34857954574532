import React, {useEffect, useState} from 'react';
import {fetchNewsData} from "../api/newsService";
import NewsListItems from "../../../entities/news/NewsListItems";
import {CircularProgress, Divider} from "@mui/material";
import PostRow from "../../../entities/news/PostRow";
import NewsLayout from "../../../entities/news/NewsLayout";

const NewsList = () => {
    const [news, setNews] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const newsData = await fetchNewsData(2023, 1, 'desc');
                setNews(newsData['result'] ? newsData['result'] : []);
                setIsLoading(false);
            } catch (error) {
                console.log(error)
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    if (isLoading){
        return(
            <NewsLayout>
                <CircularProgress/>
            </NewsLayout>
        )
    }
    return (
        <NewsLayout>
            {news.map((post, index) =>{
                return(
                    <PostRow
                        key={post.id}
                        title={post.title}
                        date={post.date}
                        id={post.id}
                        subtitle={post.subtitle}
                    />
                )
            })}
        </NewsLayout>
    );
};

export default NewsList;