import React, {useContext, useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import useMediaQuery from "@mui/material/useMediaQuery";
import {Context} from "../index";
import {useSnackbar} from "notistack";
import allEndpoints from "../shared/http";
import {Button, CircularProgress, Stack, TextField, Typography} from "@mui/material";
import InputMask from "react-input-mask";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import palette from "../shared/theme/palette";
import axios from "../shared/http/axios";

const FeedbackForm = observer(({ tariffName=null, cityMode=false, handleClose }) => {
    const isMobile = useMediaQuery('(max-width:600px)');
    const {store} = useContext(Context)
    const {enqueueSnackbar} = useSnackbar();
    const [cityValue, setCityValue] = useState('')
    const [nameValue, setNameValue] = useState('')
    const [phoneValue, setPhoneValue] = useState('')
    const [isLoading, setIsLoading] = useState(false);
    const [acceptTerms, setAcceptTerms] = useState(false);

    const sendFeedback = async () => {
        setIsLoading(true);
        try {
            const response = await axios.post("https://www.969975-cv27771.tmweb.ru:3010/feedback", {
                "subject": 'Подключение',
                "to": 'spektr.feedback@yandex.ru',
                "body": `Имя: ${nameValue},
                    Телефон: ${phoneValue},
                    Город: ${cityValue},
                    Тариф: ${tariffName},
                `
            })
            // Проверка HTTP-статуса ответа
            if (response.status === 200) {
                enqueueSnackbar('Заявка отправлена, мы свяжемся с вами в ближайшее время', {variant: 'success'});
                if (handleClose){
                    handleClose()
                }
            } else {
                console.error('Ошибка', response.statusText);
                enqueueSnackbar('Произошла ошибка, попробуйте позже', {variant: 'error'});

            }
        } catch (error) {
            // Обработка ошибки сети
            enqueueSnackbar('Произошла ошибка, попробуйте позже', {variant: 'error'});

        } finally {
            setIsLoading(false);
        }

    };
    useEffect(() => {
        if(store.cityOption) {
            setCityValue(store.cityOption.name);
        }
    }, [store.cityOption]);
    return (
        <>
            <Stack alignItems={!isMobile && "flex-start"} spacing={2} width={'100%'} maxWidth={480}>
                {
                    cityMode &&
                    <TextField
                        name="City"
                        variant="standard"
                        label="Город проживания"
                        value={cityValue}
                        onChange={(event) => setCityValue(event.target.value)}
                        fullWidth

                    />
                }
                <TextField
                    name="FirstName"
                    variant="standard"
                    label="Ваше имя"
                    fullWidth
                    value={nameValue}
                    onChange={(event) => setNameValue(event.target.value)}

                />
                <InputMask
                    mask="8 (999) 999-99-99"
                    value={phoneValue}
                    onChange={event => {setPhoneValue(event.target.value)}}
                >
                    {(inputProps) => (
                        <TextField
                            {...inputProps}
                            name="phone"
                            fullWidth
                            type="text"
                            variant="standard"
                            label="Номер телефона"
                            color="primary"
                            inputComponent={InputMask}

                        />
                    )}
                </InputMask>
            </Stack>
            <FormControlLabel
                control={
                    <Checkbox
                        sx={{color: palette.grey['200'],p: 0, paddingRight: 1}}
                        color="primary"
                        checked={acceptTerms}
                        onChange={(event) => setAcceptTerms(event.target.checked)}
                    />
                }
                label={
                    <Typography
                        variant="body2"
                        sx={{ lineHeight: isMobile && '16px', fontSize: isMobile && '12px' }}
                    >
                        Я принимаю условия обработки персональных данных
                    </Typography>
                }
            />
            {
                isLoading
                    ? <CircularProgress/>
                    : <Button
                        variant={"contained"}
                        onClick={sendFeedback}
                    >
                        Отправить
                    </Button>
            }
        </>
    );
});

export default FeedbackForm;