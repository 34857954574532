import React from 'react';
import {Divider, Stack} from "@mui/material";

const NewsLayout = ({ children }) => {
    return (
        <Stack pb={10}>
            {children}
            <Divider color={"#CCC"} sx={{height: 2}}/>
        </Stack>
    );
};

export default NewsLayout;