import React  from 'react';
import { Paper, Stack, Typography} from "@mui/material";
import FaqImg from "../../shared/svg/FAQImg";
import useMediaQuery from "@mui/material/useMediaQuery";
import FeedbackForm from "../../features/FeedbackForm";

const ContactForm = () => {
    const isMobile = useMediaQuery('(max-width:600px)');

    return (
        <Stack  pb={10} width={'100%'}>
            <Paper sx={{width: '100%'}}>
                <Stack p={{ xs: 2, md: 4 }} spacing={{ xs: 1, md: 3 }} direction={isMobile ? 'column-reverse' : "row"} justifyContent={"space-between"}>
                    <Stack width={isMobile ? '100%' : "45%"} pt={{xs: 3}}>
                        <Typography variant={'h2'} mb={2}>Остались вопросы?</Typography>
                        <Typography variant={'primaryMedium'} mb={3}>Заполните простую форму и мы с вами
                            свяжемся.</Typography>
                        <Stack alignItems={!isMobile && "flex-start"} spacing={{ xs: 3, md: 4 }}>
                            <FeedbackForm  />
                        </Stack>
                    </Stack>
                    <FaqImg
                        width={isMobile ? '100%' : '560px'}
                        height={isMobile ? 'auto' : '320px'}
                    />
                </Stack>
            </Paper>
        </Stack>
    );
};

export default ContactForm;