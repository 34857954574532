import React from 'react';
import {Box, Button, Modal, Paper, Stack, Typography} from "@mui/material";
import FeedbackForm from "../features/FeedbackForm";
import useMediaQuery from "@mui/material/useMediaQuery";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {xs: '90%', md: 'auto'}
};

const FeedbackModal = ({ tariffName, name }) => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const isMobile = useMediaQuery('(max-width:800px)');
    return (
        <Box>
            <Button variant={"contained"} sx={{boxShadow: 'none'}} onClick={handleOpen} color={"primary"} fullWidth>{name}</Button>
            <Modal
                open={open}
                onClose={handleClose}
                keepMounted={true}
            >
                <Box sx={style}>
                    <Paper >
                        <Stack p={{ xs: 3, md: 10 }} spacing={{ xs: 3, md: 3 }} maxWidth={680} width={'100%'}>
                            <Stack spacing={3}>
                                <Typography variant={'h2'}>
                                    {
                                        tariffName ? `Готовы подключиться‎ к тарифу ${tariffName}?` :
                                            'Готовы подключиться‎?'
                                    }
                                </Typography>

                            </Stack>
                            <Stack alignItems={!isMobile && "flex-start"} spacing={{ xs: 3, md: 4 }}>
                                <FeedbackForm cityMode={true} tariffName={tariffName} handleClose={handleClose}/>
                            </Stack>
                        </Stack>
                    </Paper>
                </Box>
            </Modal>
        </Box>
    );
};

export default FeedbackModal;